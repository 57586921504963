/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */
'use strict';

var countries = require('./countries'),
    cq = require('./cq'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    headermenu = require('./headermenu'),
    headerslider = require('./headerslider'),
    headersearch = require('./headersearch'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    customdialog = require('./customdialog'),
    validator = require('./validator'),
    header = require('./pages/header'),
    footer = require('./pages/footer'),
    ontour = require('./pages/homepage/ontour'),
    recprod = require('./pages/homepage/recprod'),
    accountfj = require('./pages/accountfj'),
    homepage = require('./pages/homepage/homepage'),
    accordionCollapse = require('./accordion-collapse'),
    smschannel = require('./smschannel/smschannel'),
	tabs = require('./tabs'),
	myJoysCarousel = require('./my-joys-carousel'),
	einsteinTabs = require('./pages/homepage/einstein-tabs');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}
require('./jquery-ext')();
require('./cookieprivacy')();

//initialize YouTube API
require('./youtube')();

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('click', '.ui-widget-overlay', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        }).on('touchstart', function () {
        $(this).addClass('touch');
	});

    if (util.isMobile()) {
        $('body').addClass('touch');
    }

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
        $(this).toggleClass('open');
    });

	$('.secondary-navigation .customer-service-mobile-drawer').click(function () {
		$(this).toggleClass('active');
		$(this).find('.header').toggleClass('open');
	});

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle:not(.refinement h3)').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0) {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({
                color: '#999999'
            }, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({
                    color: '#333333'
                }, 500, 'linear');
        });
    }

    $('.email-alert-signup-popup').on('submit', function (e) {
        e.preventDefault();
        var emailForm = $(this);

        if (emailForm.valid() === false) {
            return false;
        }
        var submitButton = emailForm.find('button[type="submit"]');
        var emailField = emailForm.find('.input-text').first();
        var email = emailField.val();
        var successMessage = $('#recprod-signup-ty');

        $.get('/ajax/site-intercept/intercept-manager.aspx?e=' + email, function (data) {
            data = JSON.parse(data);
            if ('status_code' in data) {
                if (data.statusCode === 200) {
                    emailField.hide();
                    submitButton.hide();
                    successMessage.show();
                } else {
                    $('#footer-signup-error').html(data.message).show();
                }
            } else {
                $('#footer-signup-error').html('internal error').show();
            }
        });
    });

    $('#term, .privacy-policy, .save-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600,
                open: function () {
                    $('#dialog-container').animate({
                        scrollTop: 0
                    }, '100');
                }
            }
        });
    });
    // print handler
    $('.print-page').on('click', function () {
        window.print();
        return false;
    });
    // Change Countries
    $(document).on('click', '.countries-container > a', function (e) {
        e.preventDefault();
        $(this).next().toggle();
        $('.down').toggleClass('revert');
    });
    $(document).on('click', '.close-countries', function (e) {
        e.preventDefault();
        $('.country-container').hide();
        $('.down').removeClass('revert');
    });
    $(document).on('click', '.mobile-hamburger-toggle', function (e) {
        e.preventDefault();
        $('.country-container').hide();
    });
    if ($('.intersitial-message-wrapper').length) {
        var timeOut = location.hash ? 2000 : 0;
        setTimeout(function () {
            dialog.open({
                html: $('.intersitial-message-wrapper').html(),
                options: {
                    dialogClass: 'no-close intersitial-message-modal',
                    width: 900
                }
            });
        }, timeOut);
        $('.show-countries').on('click', function () {
            dialog.close();
            if ($('.countries-container > a').length > 0) {
                $('.countries-container > a').click();
                $('html, body').animate({
                    scrollTop: $('.countries-container > a').offset().top
                }, 1000);
            } else {
                $('.countries-container .icon-expand').click();
                $('html, body').animate({
                    scrollTop: $('.countries-container .icon-expand').offset().top
                }, 1000);
            }
        });
    }
    // Footer Return
    $('.footer-item h3').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.footer-item').toggleClass('active');
    });
    $('.footer-return span').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });
    // FootJoy User Menu
    $('.menu-utility-user .js-user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('is-active');
        $('.header-mobile-close.js-header-mobile-close.close-cart-button').removeClass('close-cart-button');
        $('.mini-cart-content').removeClass('cart-show');
        $('#wrapper').removeClass('menu-active');
        $('.menu-toggle').removeClass('active');
    });
    $('#main').on('click', function () {
        $('.user-info').removeClass('is-active');
    });
    // FootJoy Help Menu
    $('.js-help-menu-trigger').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.menu-help').toggleClass('active');
    });
    $('#help >a').on('click', function (e) {
        e.preventDefault();
    });
    $('.menu-utility-user #help').on('mouseenter', function () {
        $(this).addClass('active');
    });
    $('.menu-utility-user #help').on('mouseleave', function () {
        $(this).removeClass('active');
    });
    //Titleist menu utility
    $('.menu-utility-user.titliest > #search-cell > a').on('click', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('is-open');
    });
    //Titleist forms in assets

    $('#searchForPlayer button[type="submit"]').click(function (e) {
        $('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
        var input = $(this).siblings('input');
        if (!input.val() && !input.hasClass('zip-error')) {
            e.preventDefault();
            input.addClass('zip-error').after('<span class="zip-error">Enter a player\'s name</span>');
        } else if (!input.val() && input.hasClass('zip-error')) {
            e.preventDefault();
        } else if (input.val()) {
            $('#searchForPlayer').submit();
        }
    });
    $('#zipFindGolfShop button[type="submit"]').click(function (e) {
        $('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
        var input = $(this).siblings('input');
        if (!input.val() && !input.hasClass('zip-error')) {
            e.preventDefault();
            input.addClass('zip-error').after('<span class="zip-error">Please enter an address</span>');
        } else if (!input.val() && input.hasClass('zip-error')) {
            e.preventDefault();
        } else if (input.val()) {
            $('#zipFindGolfShop').submit();
        }
    });
    $('#searchHeaderSubmit button[type="submit"]').click(function (e) {
        $('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
        var input = $(this).siblings('input');
        if (!input.val() && !input.hasClass('zip-error')) {
            e.preventDefault();
            input.addClass('zip-error').after('<span class="zip-error">Please enter a search term</span>');
        } else if (!input.val() && input.hasClass('zip-error')) {
            e.preventDefault();
        } else if (input.val()) {
            $('#searchHeaderSubmit').submit();
        }
    });

    $('.footer-item .footer-dd-container .has-dropdown').on('click', function () {
        if (util.getViewport() < 768) {
            $(this).siblings('ul').toggle();
            $(this).toggleClass('active');
        }
    });

    //Titleist footer js
    $('.menu-languages .btn-toggle').on('click', function () {
        $(this).parents('.has-children').toggleClass('is-open');
    });

    $('.collapsible-menu-heading').on('click', function () {
        $(this).siblings('.collapsible-menu').toggleClass('is-active');
        if ($(this).find('.collapsible-icon').html() === '+') {
            $(this).find('.collapsible-icon').html('-');
        } else {
            $(this).find('.collapsible-icon').html('+');
        }
    });

    //Titleist header js
    $(document).ready(function () {
        var $body = $('body');
        var $nav = $('.titleist-nav');
        var button;

        function navClose() {
            if ($body.hasClass('nav-is-open')) {
                $body.removeClass('nav-is-open');
                $('html').removeClass('nav-fix');
                $body.find('.modal-overlay').remove();
            }
        }
        $nav.find('.nav-item > a:not(.no-sub)').on('click', function (e) {
            e.preventDefault();
            var settings = {
                speed: 400,
                classname: 'is-active'
            };
            var $navLink = $(this);
            var navItem = $navLink.parent();
            var subMenu = navItem.find('.sub-menu');
            if ($body.width() >= 800) {
                if (navItem.hasClass(settings.classname)) {
                    subMenu.slideUp(100, function () {
                        navItem.removeClass(settings.classname);
                        subMenu.removeAttr('style');
                        navClose();
                    });
                } else {
                    if ($nav.find('.nav-item.is-active').length) {
                        $nav.find('.nav-item.is-active').find('.sub-menu').slideUp(100, function () {
                            navItem.siblings().removeClass(settings.classname).find('.sub-menu').removeAttr('style');
                            navItem.addClass(settings.classname);
                            subMenu.hide().slideDown(200, function () {
                                subMenu.removeAttr('style');
                                navClose();
                            });
                        });
                    } else {
                        navItem.addClass(settings.classname);
                        subMenu.hide().slideDown(200, function () {
                            subMenu.removeAttr('style');
                        });
                    }
                }
            } else {
                navItem.siblings().removeClass(settings.classname);
                if (navItem.hasClass(settings.classname)) {
                    navItem.removeClass(settings.classname);
                    navClose();
                } else {
                    navItem.addClass(settings.classname);
                    if ($('.modal-overlay').length === 0) {
                        $body.addClass('nav-is-open').prepend('<div class="modal-overlay" />');
                        $('html').addClass('nav-fix');
                        $('.modal-overlay').hide().fadeIn(300);
                    }
                }
            }
        });
        $body.on('click', '.modal-overlay', function () {
            button = $nav.find('.nav-item.is-active').find('> a');
            button.closest('.nav-item').removeClass('is-active');
            navClose();
        });
        $(document).keyup(function (event) {
            if (event.keyCode === 27) {
                var isOpen = $('.titleist-menu').find('.is-open');
                $nav.find('.nav-item.is-active').removeClass('is-active');
                navClose();
                if ($body.hasClass('menu-is-open')) {
                    isOpen.find('.btn-toggle').trigger('click').focus();
                }
                isOpen.find('.is-open').find('.menu-content').attr('aria-hidden', 'true');
            }
        });
    });
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
	if (SitePreferences.ENCHANCED_MINICART) {
        $('body').addClass('enable-slideout-cart');
    }
    // load js specific styles
    util.limitCharacters();
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    catlanding: require('./pages/catlanding'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    configurator: require('./pages/configurator'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator-kr'),
    registerProductDetails: require('./pages/fi/registerProductDetails'),
    protection: require('./pages/protection/protection')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        util.initializeParamsInURL();

        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        window.pdpRecommendationsInit();
        searchplaceholder.init();
        headermenu.init();
        headersearch.init();
        headerslider.init();
        cq.init();
        if (!(window.pageContext && window.pageContext.ns === 'protection')) {
            customdialog.init();
        }
        homepage.init();
        header.init();
        recprod.init();
        ontour.init();
        accountfj.init();
        footer.init();
        accordionCollapse.init();
		tabs.init();
		myJoysCarousel.init();
		einsteinTabs.init();
        if (SitePreferences.SMS_CHANNEL_ENABLED) {
            smschannel.initSMSForm();
            if (window.pageContext.ns == 'checkout') {
                $(document).on('keyup change', 'form.checkout-shipping.sms-enabled .sms-channel-section-on-checkout-page input.smschannelphone', function () {
                    var currentField = $(this);
                    if ($(this).is(":-webkit-autofill")) {
                        setTimeout(function () {
                            var currentValue = currentField.val();
                            var ootbPhoneField = currentField.closest('.checkout-shipping.sms-enabled').find('input#dwfrm_singleshipping_shippingAddress_addressFields_phone');
                            ootbPhoneField.val(currentValue);
                            currentField.trigger('blur');
                        }, 10);
                    } else {
                        setTimeout(function () {
                            var currentValue = currentField.val();
                            var ootbPhoneField = currentField.closest('.checkout-shipping.sms-enabled').find('input#dwfrm_singleshipping_shippingAddress_addressFields_phone');
                            ootbPhoneField.val(currentValue);
                        }, 10);
                    }
                });
            }
        }

		// Update Username Taken Error massege of email field on Register (Header-Register) page
		var loginRegisterPage = $(".pt_account_login_register");
		if (loginRegisterPage.length > 0) {
			var emailField = $("#dwfrm_profile_customer_email");
			var emailFieldError = emailField.closest('.form-row.username.error').find('.form-caption.error-message');
			var defaultEmailUsernameTakenError = emailField.attr('data-defaultusernametakenerror');
			var customEmailUsernameTakenError = $('.email-field-custom-error').html();
			var customEmailUsernameTakenErrorLink = $('.email-field-custom-error').attr('data-msg-link');
			if (emailField.length > 0 && emailFieldError.text() === defaultEmailUsernameTakenError) {
				emailFieldError.html(customEmailUsernameTakenError);
				emailFieldError.find('a').attr('href', customEmailUsernameTakenErrorLink);
			}
		}

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
        // Titleist headroom
        var offset = $('#banner').outerHeight();
        if (window.headroom !== undefined) {
            if (window.headerOffset !== undefined && window.headerOffset === offset) {
                return;
            }

            window.headroom.destroy();
            $('#banner').removeData('headroom');

            window.headerOffset = offset;
            window.headroom = new Headroom($('#banner').get(0), {
                'offset': offset,
                'tolerance': 5,
                'classes': {
                    'initial': 'banner--fixed',
                    'unpinned': 'banner--down'
                },
                onUnpin: function () {
                    // Close open navigation when scrolling down
                    $('.titleist-nav').find('.nav-item.is-active').removeClass('is-active');
                }
            });

            window.headroom.init();
            $('html').css('margin-top', offset);
        }

        // Update locales in country selector
        if (SitePreferences.PROTECTION_HIDE_BLOCK_LOCALE) {
            var protectionLocalesToBlock = Protection.protectionLocalesToBlock;
            if (protectionLocalesToBlock) {
                for (var i = 0; i < protectionLocalesToBlock.length; i++) {
                    // Hide locales in country selector
                    var protectionLocale = protectionLocalesToBlock[i];
                    var locale = '/' + protectionLocale + '/';
                    $('#countryMenu .location a[href*="' + locale + '"]').parent().remove();
                }
            }
        }
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

var getLeftSideElementEdgePosition = function (element) {
    var data = element.getBoundingClientRect();
    return {
        x: /*data.left + data.width*/ 0,
        y: data.left + data.width
    };
};
var getRightSideElementEdgePosition = function (element) {
    var data = element.getBoundingClientRect();
    return {
        x: /*data.left*/ 0,
        y: data.left
    };
};

var getDistanceBetweenElements = function (a, b) {
    var aPosition = getLeftSideElementEdgePosition(a);
    var bPosition = getRightSideElementEdgePosition(b);

    return Math.sqrt(
        Math.pow(aPosition.x - bPosition.x, 2) +
        Math.pow(aPosition.y - bPosition.y, 2)
    );
};
// initialize app
$(document).ready(function () {
    app.init();
    var headerSearchElem = document.getElementsByClassName('header-search')[0];

	function getDistanceBetween() {
		var distance = getDistanceBetweenElements(document.getElementsByClassName('logo-container')[0], headerSearchElem);
		$(headerSearchElem).css('max-width', 'initial');
		if ($(window).width() < 880) {
			$(headerSearchElem).css('width', '100%');
		} else {
			if ($(window).width() < 1270) {
				$(headerSearchElem).css('width', '180px');
			} else {
				$(headerSearchElem).css('width', '291px');
			}
		}
	}

	var safeToOpen = true;
	var $submitSearchBtn = $('form[name=simpleSearch] button');
	var $searchInput = $('form[name=simpleSearch] #q');
	$('#mobileSearchShowBtn').on('click', function (e) {
		$('.header-main').addClass('search-open');
		if (safeToOpen) {
			$(headerSearchElem).css('width', '0');
			getDistanceBetween();
            $searchInput.focus();
			safeToOpen = false;
		} else {
			if ($searchInput.val()) {
				$submitSearchBtn.click();
			} else {
				safeToOpen = true;
			}
		}
	});

	$('#closeSearch').on('click', function () {
		safeToOpen = true;
		$('.header-main').removeClass('search-open');
		$(headerSearchElem).css('width', '0');
	});

	$('#q').on('click', function () {
		$(window).off('resize');
	});

	$(window).on('resize', function () {
		getDistanceBetween();
		safeToOpen = true;
	});
    $('.header-search').on('click', function () {
        $('.header-dropdown').removeClass('active');
        $('#wrapper').removeClass('menu-active');
        $('.menu-toggle').removeClass('active');
        $('.user-info').removeClass('is-active');
        $('.header-mobile-close.js-header-mobile-close.close-cart-button').removeClass('close-cart-button');
        $('.mini-cart-content').removeClass('cart-show');
    });
    $('#closeSearch').on('click', function () {
        $('.header-dropdown').removeClass('active');
        $('#wrapper').removeClass('menu-active');
        $('.menu-toggle').removeClass('active');
        $('.user-info').removeClass('is-active');
        $('.header-mobile-close.js-header-mobile-close.close-cart-button').removeClass('close-cart-button');
        $('.mini-cart-content').removeClass('cart-show');
    });
    $('.pt_product-details').mouseup(function () {
        $('.mini-cart-link').on('click', function () {
            $('#wrapper').removeClass('menu-active');
            $('.menu-toggle').removeClass('active');
        });
        $('.mini-cart-total').mouseenter(function () {
            $('.header-mobile-close.js-header-mobile-close').addClass('close-cart-button');
            $('.mini-cart-content').addClass('cart-show');
            $('#wrapper').removeClass('menu-active');
            $('.menu-toggle').removeClass('active');
            $('.user-info').removeClass('is-active');
        });
        $('.header-mobile-close.js-header-mobile-close').mouseleave(function () {
            $('.header-mobile-close.js-header-mobile-close').removeClass('close-cart-button');
            $('.mini-cart-content').removeClass('cart-show');
        });
        $('.mini-cart-content').mouseenter(function () {
            $('.header-mobile-close.js-header-mobile-close').addClass('close-cart-button');
            $('.mini-cart-content').addClass('cart-show');
            $('#wrapper').removeClass('menu-active');
            $('.menu-toggle').removeClass('active');
            $('.user-info').removeClass('is-active');
        });
        $('.mini-cart-content').mouseleave(function () {
            $('.header-mobile-close.js-header-mobile-close').removeClass('close-cart-button');
            $('.mini-cart-content').removeClass('cart-show');
        });
    });
    $(window).on('resize', function () {
        if ($(window).width() < 880) {
            $('.mini-cart-link').on('click', function (e) {
                if ($('.pt_checkout').length) {
                    $('#wrapper').removeClass('menu-active');
                    $('.menu-toggle').removeClass('active');
                } else {
                    e.preventDefault();
                    $('.header-mobile-close.js-header-mobile-close').addClass('close-cart-button');
                    $('.mini-cart-content').addClass('cart-show');
                    $('#wrapper').removeClass('menu-active');
                    $('.menu-toggle').removeClass('active');
                    $('.user-info').removeClass('is-active');
                    $('.header-dropdown').removeClass('active');
                }
            });
            $('.header-mobile-close.js-header-mobile-close').on('click', function () {
                $('.header-mobile-close.js-header-mobile-close').removeClass('close-cart-button');
                $('.mini-cart-content').removeClass('cart-show');
            });
        } else {
            $('.mini-cart-link').on('click', function () {
                $('#wrapper').removeClass('menu-active');
                $('.menu-toggle').removeClass('active');
            });
            $('.mini-cart-total').mouseenter(function () {
                $('.header-mobile-close.js-header-mobile-close').addClass('close-cart-button');
                $('.mini-cart-content').addClass('cart-show');
                $('#wrapper').removeClass('menu-active');
                $('.menu-toggle').removeClass('active');
                $('.user-info').removeClass('is-active');
            });
            $('.mini-cart-total').mouseleave(function () {
                $('.header-mobile-close.js-header-mobile-close').removeClass('close-cart-button');
                $('.mini-cart-content').removeClass('cart-show');
            });
            $('.mini-cart-content').mouseenter(function () {
                $('.header-mobile-close.js-header-mobile-close').addClass('close-cart-button');
                $('.mini-cart-content').addClass('cart-show');
                $('#wrapper').removeClass('menu-active');
                $('.menu-toggle').removeClass('active');
                $('.user-info').removeClass('is-active');
            });
            $('.mini-cart-content').mouseleave(function () {
                $('.header-mobile-close.js-header-mobile-close').removeClass('close-cart-button');
                $('.mini-cart-content').removeClass('cart-show');
            });
        }
    });
    $(window).on('homeRecommenderSliderLoaded', function () {
        recprod.init();
    });
    // Set return url for Login from header
    if ($('.header-dropdown.account button[name="dwfrm_login_login"]').length > 0) {
        var href = location.href;
        if (href.indexOf('#') !== -1) {
            href = href.split('#')[0];
        }
        if (!href.endsWith('Header-Login') && !href.endsWith('Header-Register') && !href.endsWith('Account-StartRegister') && !href.endsWith('register')) {
            $('.header-dropdown.account input[name="returnUrl"]').val(href);
        }

        var endpointUrl = href.split('?')[0];
        var orderStatusPage = endpointUrl.endsWith('status') || endpointUrl.endsWith('Account-OrderStatus');
        if (SitePreferences.IS_FOOTJOY_SITES && orderStatusPage) {
            $('.header-dropdown.account input[name="returnUrl"]').val(Urls.somOrderHistoryPage);
        }
    };
    // Init dataLayer event for assets and slots
    $(document).on('click', '.data-content-asset a, .data-content-asset button', function (event) {
        var $target = $(event.target);
        var $section = $target.closest('.data-content-asset');
    
        var pageSection;
        var navEl = $target.closest('li.active.open');
        
        if (navEl.length) {
            pageSection = navEl.find('h2').text().trim();
        } else {
            pageSection = $section.find('h2').text().trim();
        }
        var pageContentClass = $target.attr('class') && $target.not('img').length ? $target.attr('class').replaceAll(' ', '_').split('_')[0] : $target.closest('div').attr('class').replaceAll(' ', '_').split('_')[0];
        var targetText = $target.text().trim();
        var pageContent = targetText ? pageContentClass + '_' + targetText : pageContentClass;
        if (dataLayer) {
            dataLayer.push({
                event: 'page_interaction',
                page_type: window.dataLayerData.pageView || window.dataLayerData.pageType,
                page_section: pageSection,
                page_content: pageContent
            });
        }
    });
    // Set return url for Logout from header
    $('.user-info a.user-logout').on('click', function (e) {
        e.preventDefault();
        var url = $('.user-info a.user-logout').attr('href');
        if (url) {
            var returnUrl = location.href;
            if (returnUrl.indexOf('#') !== -1) {
                returnUrl = returnUrl.split('#')[0];
            }
            var redirectUrl = util.appendParamsToUrl(url, {
                'returnUrl': returnUrl
            });
            window.location.href = redirectUrl;
        }
    });
    // Init dataLayer event 'page_interaction' for Page Designer
    $(document).on('click', '.page-designer-reference a, .page-designer-reference button, .storepage a, .storepage button', function (e) {
        var pageType = dataLayerData ? dataLayerData.pageView : '';
        var pageSection = $(this).closest('div[data-component-name]').attr('data-component-name') || '';
        var needPushData = false;
        if ($(this).attr('data-component-content')) {
            var pageContent = $(this).attr('data-component-content').trim();
            needPushData = true;
        }else if ($(this).children().length == 0) {
            var pageContent = $(this).text() ? $(this).text().trim() : '';
            needPushData = true;
        }

        if (dataLayer && needPushData) {
            dataLayer.push({
                event: 'page_interaction',
                page_type: pageType,
                page_section: pageSection,
                page_content: pageContent
            });
        }
    });

    if (SitePreferences.SMS_CHANNEL_ENABLED) {
        smschannel.initSMSForm();

        // Add event to submit Register Step2 from on landing page
        $('#custom-registration .account-toggle-content#registerLandingStep2 form button[type="submit"]').on('click', function (e) {
            e.preventDefault();

            var errorPlaceHolderElement = $('#custom-registration .account-toggle-content#registerLandingStep2 #errorPlaceHolder');
            var parentElement = $(this).closest('form');

            smschannel.subscribe(
                smschannel.CREATED_BY.SITE_REGISTRATION,
                parentElement,
                errorPlaceHolderElement,
                function () {
                    // submit form with preferences
                    parentElement.submit();
                }
            );
        });

        // Add event to submit sms form on order confirmation page
        if (page.ns == 'orderconfirmation') {
            $(document).on('click', '#confirmationOrderSMSForm form button[type="submit"]', function (e) {
                e.preventDefault();

                var errorPlaceHolderElement = $('#confirmationOrderSMSForm #errorPlaceHolder');
                var parentElement = $(this).closest('form');

                smschannel.subscribe(
                    smschannel.CREATED_BY.SITE_CHECKOUT,
                    parentElement,
                    errorPlaceHolderElement,
                    function () {
                        // TODO: ASA-5006 Add successfully subscription mesage or smth else
                        $('#confirmationOrderSMSForm #signUpBySmsConfirmationForm').addClass("visually-hidden");
                        $('#confirmationOrderSMSForm #signUpBySmsResultConfirmationForm').removeClass("visually-hidden");
                    }
                );
            });

            // Add event to submit for Register Step1 from on order confirmation page
            $('.confirmationregister #regiserStep1 form button[type="submit"]').on('click', function (e) {
                e.preventDefault();
                var form = $(this).closest('form');

                var data = $(form).serialize();
                var url = $(form).attr('action');
                url = util.appendParamsToUrl(url, {
                    'format': 'ajax'
                });
                $.ajax({
                    type: 'POST',
                    url: url,
                    data: data,
                    success: function (res) {
                        if (typeof res === 'object' && res !== null && res.redirectUrl) {
                            var redirectUrl = res.redirectUrl;
                            var profileSecureKeyValue = res.profileSecureKeyValue;
                            // hide form 1 and open form 2
                            $('.confirmationregister #regiserStep1').hide();
                            $('.confirmationregister #regiserStep2').show();
                            $('.confirmationregister #regiserStep2').find('input[name="dwfrm_profile_securekey"]').attr('value', profileSecureKeyValue);
                            $('.confirmationregister #regiserStep2').find('input[name="returnUrl"]').attr('value', redirectUrl);

                            // hide category option section in case if eNews is not selected
                            // to match logic of selection category options on Account-Preference page.
                            // Set addtoemaillist input with selected value got from register form #1
                            // as addtoemaillist is used in exusting subscription logic
                            var isAddToEmailChecked = $('.confirmationregister #regiserStep1').find('input[name="dwfrm_profile_customer_addtoemaillist"]').is(':checked');
                            if (!isAddToEmailChecked) {
                                $('.confirmationregister #regiserStep2').find('.category-options').removeClass('visually-hidden').addClass('visually-hidden');
                            } else {
                                $('.confirmationregister #regiserStep2').find('input[name="dwfrm_profile_customer_addtoemaillist"]').attr('checked', 'checked');
                            }
                        } else {
                            // it will load login page with errors as it was before
                            // when something was wrong with registration via header
                            var headerRegisterFailed = window.Urls.headerRegisterFailed;
                            window.location = headerRegisterFailed;
                        }
                    }
                });
            });
            // Add event to submit Register Step2 from on order confirmation page
            $('.confirmationregister #regiserStep2 form button[type="submit"]').on('click', function (e) {
                e.preventDefault();

                var errorPlaceHolderElement = $('.confirmationregister #regiserStep2 #errorPlaceHolder');
                var parentElement = $(this).closest('form');

                smschannel.subscribe(
                    smschannel.CREATED_BY.SITE_REGISTRATION,
                    parentElement,
                    errorPlaceHolderElement,
                    function () {
                        // submit form with preferences
                        parentElement.submit();
                    }
                );
            });
        }
    }
});
