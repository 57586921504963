'use strict';

var ajax = require('../../ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('../../progress'),
	giftcard = require('../../giftcard'),
	util = require('../../util'),
	cybersource = require('../../cybersource'),
	adyenCse = require('../../adyen-cse'),
	daumPostcodeLookup = require('../../daumPostcodeLookup'),
	openInvoice = require('../../openinvoice'),
	adyenCheckout = require('./adyen-checkout');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
	var $creditCard = $('[data-method="CREDIT_CARD"]');
	$creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
	$creditCard.find('input[name$="_type"]').val(data.type).trigger('change');
	$creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
	$creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
	$creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
	$creditCard.find('input[name$="_cvn"]').val('').trigger('change');
	// Update the fields specific to Cybersource
	cybersource.init();
	cybersource.setFields(data);
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
	// load card details
	var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
	ajax.getJson({
		url: url,
		callback: function (data) {
			if (!data) {
				window.alert(Resources.CC_LOAD_ERROR);
				return false;
			}
			setCCFields(data);
			updateCardIcons();
		}
	});
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
	var $paymentMethods = $('.payment-method');
	var $paymentMethodTabs = $('.payment-method-tab');
	$paymentMethods.removeClass('payment-method-expanded');
	$paymentMethodTabs.removeClass('selected');
	$paymentMethods.removeAttr('style');
	$paymentMethods.find('.input-text, .input-select').attr('tabindex', '-1');

	var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
	var $selectedPaymentMethodTab = $paymentMethodTabs.filter('[data-method="' + paymentMethodID + '"]');
	if ($selectedPaymentMethod.length === 0) {
		$selectedPaymentMethod = $('[data-method="Custom"]');
	}
	$selectedPaymentMethod.addClass('payment-method-expanded');
	$selectedPaymentMethodTab.addClass('selected');
	$selectedPaymentMethod.find('.input-text, .input-select').attr('tabindex', '0');

	// ensure checkbox of payment method is checked
	$('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
	var $selectedInput = $('input[value=' + paymentMethodID + ']');
	$selectedInput.prop('checked', true);

	if ((paymentMethodID !== 'scheme' && paymentMethodID !== 'CREDIT_CARD' && paymentMethodID !== 'kakaopay' && paymentMethodID !== 'kcp_creditcard' && paymentMethodID !== 'kcp_payco' && paymentMethodID !== 'kcp_banktransfer' && paymentMethodID !== 'kcp_naverpay' && paymentMethodID !== 'paypal' && paymentMethodID !== 'directEbanking' && paymentMethodID !== 'giropay' && paymentMethodID !== 'OPEN_INVOICE') || $selectedInput.attr('processed')) {
		if (paymentMethodID !== 'scheme' && paymentMethodID !== 'kakaopay' && paymentMethodID !== 'kcp_creditcard' && paymentMethodID !== 'kcp_payco' && paymentMethodID !== 'kcp_banktransfer' && paymentMethodID !== 'kcp_naverpay' && paymentMethodID !== 'paypal' && paymentMethodID !== 'directEbanking' && paymentMethodID !== 'giropay' && paymentMethodID !== 'OPEN_INVOICE') {
			$('#billing-submit').prop('innerText', $('#billing-submit').data('placeorderText'));
			$('.checkout-privacy-policy').show();
			$('#billing-submit').show();
		} else {
			$('.checkout-privacy-policy').hide();
			$('#billing-submit').hide();
		}
	} else {
		$('.checkout-privacy-policy').hide();
		$('#billing-submit').hide();
	}

	// Select Adyen payment method for KCP
	var $currentPayType = $('[name="brandCode"]');
	var $currentSelectPaymentMethod = $('.payment-method-options');
	if ($selectedInput.attr('name') == 'brandCode' && $currentPayType.length > 0) {
		//set payment type of Adyen to the first one
		$currentSelectPaymentMethod.find('input#is-Adyen').prop('checked', true);
	} else {
		$currentSelectPaymentMethod.find('input[name="brandCode"]').prop('checked', false);
		$currentSelectPaymentMethod.find('input[name="issuerId"]').prop('checked', false);
	}

	formPrepare.validateForm();
}

/**
 * @function
 * @description Changes the payment type or issuerId of the selected payment method
 * @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
 */
function updatePaymentType(selectedPayType, test) {
	if (!test) {
		$('input[name="brandCode"]').removeAttr('checked');
	} else {
		$('input[name="issuerId"]').removeAttr('checked');
	}
	$('input[value=' + selectedPayType + ']').prop('checked', 'checked');
	formPrepare.validateForm();
}

function updateCardIcons() {
	if ($('#dwfrm_billing_paymentMethods_creditCard_type').length && $('#dwfrm_billing_paymentMethods_creditCard_type').val() !== 'null') {
		$('span.icon-Master img:not(.gray-icon)').addClass('grayscale');
		$('span.icon-Master img:not(.gray-icon)').addClass('grayscale');
		$('span.icon-Visa img:not(.gray-icon)').addClass('grayscale');
		$('span.icon-Amex img:not(.gray-icon)').addClass('grayscale');
		$('span.icon-Discover img:not(.gray-icon)').addClass('grayscale');
		$('span.icon-' + $('#dwfrm_billing_paymentMethods_creditCard_type').val() + ' img').removeClass('grayscale');
	}
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
	var $checkoutForm = $('.checkout-billing'),
		$addGiftCert = $('#add-giftcert'),
		$giftCertCode = $('input[name$="_giftCertCode"]'),
		$addCoupon = $('#add-coupon'),
		$couponCode = $('input[name$="_couponCode"]'),
		$selectPaymentMethod = $('.payment-method-options'),
		selectedPaymentMethod = $selectPaymentMethod.find('input:checked').val(),
		$payType = $('[name="brandCode"]'),
		$issuerId = $('[name="issuerId"]'),
		$issuer = $('ul#issuer'),
		selectedPayType = $payType.find(':checked').val(),
		selectedIssuerId = $issuerId.find(':checked').val();

	$checkoutForm.on('submit', function () {
		var $submitButton = $('#submitBillingButton');
		if ($submitButton.length > 0 && !($submitButton.hasClass('disabled'))) {
			progress.show($('#main'));
		}
	});
	// Initialize the billing form on the page
	formPrepare.init({
		formSelector: 'form[id$="billing"]',
		continueSelector: '[name$="billing_save"]',
		canDisable: true
	});
	// Initialize the Cybersource functionality
	cybersource.init();

	// default payment method to 'CREDIT_CARD'
	updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');

	$selectPaymentMethod.on('click', 'input[type="radio"]', function (e) {
		updatePaymentMethod($(this).val());
	});

	//Billing Change Address event
	$('#change-address').on('click', function (e) {
		e.preventDefault();
		$(this).hide();
		$('.fieldset.billing-address-static').hide();
		$('.fieldset.address').show();
		$('.checkout-billing.address .fieldset.address input.required').each(function () {
			$(this).on('change', function () {
				setTimeout(function () {
					if ($('.checkout-billing.address .fieldset.address input.required.error').length > 0) {
						$('.checkout-billing.address').addClass('required-error');
					} else {
						$('.checkout-billing.address').removeClass('required-error');
					}
				}, 100);
			});
		});
	});

	$payType.on('click', function () {
		updatePaymentType($(this).val(), false);
		//if the payment type contains issuerId fields, expand form with the values
		if ($(this).siblings('#issuer').length > 0) {
			$issuer.show();
			updatePaymentType((selectedIssuerId) ? selectedIssuerId : $issuerId[0].value, true);
		} else {
			$issuer.hide();
			$('input[name="issuerId"]').removeAttr('checked');
		}
	});

	$issuerId.on('click', function () {
		updatePaymentType($(this).val(), true);
	});

	// select credit card from list
	$('#creditCardList').on('change', function () {
		var cardUUID = $(this).val();
		if (!cardUUID) {return;}
		populateCreditCardForm(cardUUID);

		// remove server side error
		$('.required.error').removeClass('error');
		$('.error-message').remove();
	});
	$('.checkout-billing .form-row').each(function () {
		if ($(this).hasClass('label-inline')) {
			$(this).find('input').clone().insertBefore($(this).find('label'));
			$(this).find('.field-wrapper').remove();
			$(this).find('.form-caption').remove();
		}
	});
	$('.coupon-code h2').click(function () {
		$(this).toggleClass('close');
		$(this).next('.billing-coupon-code').toggleClass('close');
	});
	$('#dwfrm_singleshipping_shippingAddress_addressFields_address1').parent('.field-wrapper').next('div').addClass('address1');
	$('form.checkout-billing.address').find('.form-row .tooltip').attr('tabindex', '-1');
	$('.login-box.login-account h2').click(function () {
		$(this).toggleClass('close');
		$(this).next('.login-box-content').toggleClass('close');
	});
	$('#check-giftcert').on('click', function (e) {
		e.preventDefault();
		var $balance = $('.balance');
		if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
			var error = $balance.find('span.error');
			if (error.length === 0) {
				error = $('<span>').addClass('error').appendTo($balance);
			}
			error.html(Resources.GIFT_CERT_MISSING);
			return;
		}

		giftcard.checkBalance($giftCertCode.val(), function (data) {
			if (!data || !data.giftCertificate) {
				$balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
				return;
			}
			$balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
		});
	});

	$addGiftCert.on('click', function (e) {
		e.preventDefault();
		var code = $giftCertCode.val(),
			$error = $checkoutForm.find('.giftcert-error');
		if (code.length === 0) {
			$error.html(Resources.GIFT_CERT_MISSING);
			return;
		}

		var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
		$.getJSON(url, function (data) {
			var fail = false;
			var msg = '';
			if (!data) {
				msg = Resources.BAD_RESPONSE;
				fail = true;
			} else if (!data.success) {
				msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
				fail = true;
			}
			if (fail) {
				$error.html(msg);
				return;
			} else {
				window.location.assign(Urls.billing);
			}
		});
	});

	$addCoupon.on('click', function (e) {
		e.preventDefault();
		var $error = $checkoutForm.find('.coupon-error').empty(),
			code = $couponCode.val(),
			$success = $checkoutForm.find('.coupon.redemption');
		if (code.length === 0) {
			$error.html(Resources.COUPON_CODE_MISSING);
			return;
		}

		var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
		$.getJSON(url, function (data) {
			var fail = false;
			var msg = '';
			if (!data) {
				msg = Resources.BAD_RESPONSE;
				fail = true;
			} else if (!data.success) {
				msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
				fail = true;
			}
			if (fail) {
				$error.html(msg);
				return;
			}

			//basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
			//this will force a page refresh to display the coupon message based on a parameter message
			if (data.success && data.baskettotal === 0) {
				window.location.assign(Urls.billing);
			} else if (data.success) {
				$success.html('<span class="success">' + data.message + '</span>');
				updateSummary();
				$('body').trigger('addCoupon');
			}
		});
	});

	updateCardIcons();

	$('input[id*="creditCard_number"]').on('keyup', function (event) {
        var key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            return false;
        }
		var accountNumber = $(this).val();
		var result = "";
		if (/^(5[1-5]|2[2-7][0-2][0-9])/.test(accountNumber)) {
			// "MasterCard"
			$("span.icon-Master img").removeClass('grayscale');
			$("span.icon-Visa img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Amex img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Discover img:not(.gray-icon)").addClass('grayscale');
			$("#dwfrm_billing_paymentMethods_creditCard_type").val("MasterCard");
			$(this).val(function (index, value) {
				return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
			});
		} else if (/^4/.test(accountNumber)) {
			// "Visa"
			$("span.icon-Master img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Amex img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Discover img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Visa img").removeClass('grayscale');
			$("#dwfrm_billing_paymentMethods_creditCard_type").val("Visa");
			$(this).val(function (index, value) {
				return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
			});
		} else if (/^3[47]/.test(accountNumber)) {
			//  "Amex"
			$("span.icon-Master img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Visa img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Discover img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Amex img").removeClass('grayscale');
			$("#dwfrm_billing_paymentMethods_creditCard_type").val("Amex");
			if (accountNumber.match(/^3[47]\d{2}/)) {
				if (accountNumber.length == 4) {
					accountNumber += ' ';
					$(this).val(accountNumber);
				}
				if (accountNumber.length == 11) {
					accountNumber += ' ';
					$(this).val(accountNumber);
				}
				if (accountNumber.length > 17) {
					val = $(this).val().substr(0, $(this).val().length - 1);
					$(this).val(val);
				}
			} else {
				if (accountNumber.length > 16) {
					val = $(this).val().substr(0, $(this).val().length - 1);
					$(this).val(val);
				}
				if (accountNumber.length == 16) {
					var splits = accountNumber.match(/\d{4}/g);
					val = splits.join(' ');
					$(this).val(val);
				}
			}
		} else if (/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(accountNumber)) {
			// "Discover"
			$("span.icon-Master img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Visa img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Amex img:not(.gray-icon)").addClass('grayscale');
			$("span.icon-Discover img").removeClass('grayscale');
			$("#dwfrm_billing_paymentMethods_creditCard_type").val("Discover");
			$(this).val(function (index, value) {
				return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
			});
		}
	});
// trigger events on enter
	$couponCode.on('keydown', function (e) {
		if (e.which === 13) {
			e.preventDefault();
			$addCoupon.click();
		}
	});
	$giftCertCode.on('keydown', function (e) {
		if (e.which === 13) {
			e.preventDefault();
			$addGiftCert.click();
		}
	});
	$('.checkout-billing .input-select.country').on('change', function () {
		util.updateState($(this));
	});
	util.updateState($('.checkout-billing .input-select.country'));

    if (SitePreferences.ADYEN_CSE_ENABLED) {
        adyenCse.initBilling();
    }

    openInvoice.init();
    util.birthdayInit();

    // show hidden form in case of error
    if ($('.fieldset.address').find('.form-row.error').length > 0) {
        $('#change-address').click();
    }
    applePayInit();
    formPrepare.validateForm();
	daumPostcodeLookup.init();

	adyenCheckout.initBilling();
}

/**
 * Handles event related with ApplePay payment method on the billing page
 */
function applePayInit() {
    var applePayMethodId = 'DW_APPLE_PAY';
    // Displays ApplePay radio option if it available on the current device
    if ('ApplePaySession' in window && window.ApplePaySession.canMakePayments()) {
        $('#is-' + applePayMethodId).parent().removeClass('visually-hidden');
    }
    // Handles changing of payment method, and display 'ApplePay' button if was selected ApplePay method, continue button, in this case, will be hidden
    $('body').on('change', '.payment-method-options .form-row', function () {
        var id = $(this).find('input[type="radio"]').attr('id');
        if (id != undefined && id.indexOf(applePayMethodId) > -1) {
            $('#submitBillingButton').addClass('visually-hidden');
			$('.checkout-privacy-policy').addClass('visually-hidden');
            $('.applepay-checkout-btn').removeClass('visually-hidden');
        } else {
            $('#submitBillingButton').removeClass('visually-hidden');
			$('.checkout-privacy-policy').removeClass('visually-hidden');
            $('.applepay-checkout-btn').addClass('visually-hidden');
        }
    })
}

/**
 * @function
 * @description ripped off this function from shipping.js - updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
	var $summary = $('#secondary.summary');
	// indicate progress
	progress.show($summary);

	// load the updated summary area
	$summary.load(Urls.summaryRefreshURL + '?step=3', function () {
		// hide edit shipping method link
		$summary.fadeIn('fast');
		$summary.find('.checkout-mini-cart .minishipment .header a').hide();
		$summary.find('.order-totals-table .order-shipping .label a').hide();
		progress.hide();
	});
}
