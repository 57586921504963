'use strict';

/**
 * @private
 * @function
 * @description disable warranty specific form fields
 */
function disableWarrantySpecificFields() {
	$('[name="dwfrm_contactus_address_address1"]').val('NA');
	$('[name="dwfrm_contactus_address_address2"]').val('NA');
	$('[name="dwfrm_contactus_address_city"]').val('NA');
	$('[name="dwfrm_contactus_address_postal"]').val('11111');
	$('#RegistrationForm .input-select.state').val('OTHER');
	$('#RegistrationForm .input-select.country').trigger('change');
	$('.warranty-form-rows').hide();
}

/**
 * @private
 * @function
 * @description enable warranty specific form fields

 */
function enableWarrantySpecificFields() {
	$('[name="dwfrm_contactus_address_address1"]').val('');
	$('[name="dwfrm_contactus_address_address2"]').val('');
	$('[name="dwfrm_contactus_address_city"]').val('');
	$('[name="dwfrm_contactus_address_postal"]').val('');
	$('#RegistrationForm .input-select.state').val('');
	$('#RegistrationForm .input-select.country').trigger('change');
	$('.warranty-form-rows').show();
	$('.form-field-tooltip').hide();
}

exports.init = function init () {
	// Hide warranty specific fields
	disableWarrantySpecificFields();
	$('.warranty-form-rows .country').parent().parent().remove();
	$('.warranty-form-rows .country').remove();
	$('.contact-us-country select option[label="' + Scripts.storeLocator.vars.defaultlocation + '"]').prop('selected', true);
	// Watch for subject 'Warranty Issues' to toggle warranty-form-rows
	$('.subject-selector select').on('change', function () {
		var subject = $.trim($(this).val());
		if (subject == 'Warranty Issues') {
			// Show warranty specific fields
			enableWarrantySpecificFields();
		} else {
			// Hide warranty specific fields
			disableWarrantySpecificFields();
		}
	});
	// Handle 'state' field based on country
	$('#RegistrationForm .input-select.country').on('change', function (e) {
		if ($(this).val() != 'US' && $(this).val() != 'CA') {
			$('#RegistrationForm .input-select.state').parents('.form-row').show();
			$('#RegistrationForm select[name$="_stateCA"]').parents('.form-row').hide();
			$('#RegistrationForm .input-select.state').val('OTHER');
			$('#RegistrationForm .input-select.state').prop('disabled', true);
		} else {
			if ($(this).val() == 'US') {
				$('#RegistrationForm .input-select.state').parents('.form-row').show();
				$('#RegistrationForm select[name$="_stateCA"]').parents('.form-row').hide();
				$('#RegistrationForm .input-select.state').prop('disabled', false);
			} else {
				//CANADA
				$('#RegistrationForm .input-select.state').parents('.form-row').hide();
				$('#RegistrationForm select[name$="_stateCA"]').parents('.form-row').show();
			}
		}
	});
	$('#RegistrationForm .input-select.country').trigger('change');
};
