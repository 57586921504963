/*global daum*/
'use strict';

/**
 * @function
 * @description Initializes daum postcode lookup events
 */
function initializeEvents(formSelector) {
    var $addressForm = formSelector ? $(formSelector) : $('.address');
    var $postcodeLookupBtn = $addressForm.find('.postcode-lookup');

    $addressForm.on('keypress', function (e) {
        if (e.which == 13) {
          e.preventDefault();
        }
    });
    $postcodeLookupBtn.on('click', function (e) {
        e.preventDefault();
        // create element for embedding daum postal code dropdown
        jQuery('<div>', {
            id: 'daum-postcodes-drowdown'
        }).appendTo($('.form-row.postal-code'));
        var element = document.querySelector('#daum-postcodes-drowdown');
        // open Daum Postcode lookup modal
        new daum.Postcode({
            oncomplete: function (data) {
                var addr = '';
                var extraAddr = '';
                // Get the address value according to the address type selected by user
                if (data.userSelectedType === 'R') { // When user selects road name address type
                    addr = data.roadAddress;
                } else { // When user selects address (J)
                    addr = data.jibunAddress;
                }
                // If the address selected by a user is a road name type, the reference items are combined.
                if (data.userSelectedType === 'R') {
                    // Add if there is a legal name (in this case the last character ends with 동/로/가)
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraAddr += data.bname;
                    }
                    // If there is a building name, and it is an apartment, fill it in the address2 field.
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        $addressForm.find('input[name$="_address2"]').val(data.buildingName).trigger('change');
                    }
                } else {
                    $addressForm.find('input[name$="_address2"]').val('').trigger('change');
                }

                // Enter the postal code and address information in the appropriate form fields.
                $addressForm.find('input[name$="_postal"]').val(data.zonecode).trigger('change');
                $addressForm.find('input[name$="_address1"]').val(addr + (extraAddr ? ' (' + extraAddr + ')' : '')).trigger('change');
				$addressForm.find('input[name$="_postal"]').removeClass('error');
                $addressForm.find('input[name$="_address1"]').removeClass('error');
				$addressForm.find('span[id$="_postal-error"]').remove();
                $addressForm.find('span[id$="_address1-error"]').remove();

                if (data.sigunguCode && (data.sigunguCode.substring(0, 2) == '36')) {
                    $addressForm.find('input[name$="_states_state"]').val('50').trigger('change');
                    $addressForm.find('input[name$="_city"]').val(data.sido).trigger('change');
                } else {
                    $addressForm.find('input[name$="_city"]').val(data.sigungu).trigger('change');
                    $addressForm.find('input[name$="_states_state"]').val(data.sigunguCode ? data.sigunguCode.substring(0, 2) : '').trigger('change');
                }
            }
        }).embed(element);
    });
}

exports.init = function (formSelector) {
    initializeEvents(formSelector);
};
